import { TopPortItem, TopXPortsTable } from '@cts/cedar-public-api'

export type ImexDirection = 'imports' | 'exports'
export type SortDirection = 'asc' | 'desc'
export type TopPortsSort =
  | 'countryName'
  | 'portName'
  | 'nameSortOrdinal'
  | 'data.mom'
  | 'data.yoy'
  | 'data.ytd'

export class TopPortsTableModel {
  private _selectedImex: ImexDirection = 'exports'
  public get selectedImex(): ImexDirection {
    return this._selectedImex
  }
  public set selectedImex(value: ImexDirection) {
    this._selectedImex = value
    this.buildRows()
  }

  private _selectedSortField: TopPortsSort = 'nameSortOrdinal'
  public get selectedSortField(): TopPortsSort {
    return this._selectedSortField
  }
  public set selectedSortField(value: TopPortsSort) {
    this._selectedSortField = value
    this.buildRows()
  }

  private _selectedSortDirection: SortDirection = 'asc'
  public get selectedSortirection(): SortDirection {
    return this._selectedSortDirection
  }
  public set selectedSortDirection(value: SortDirection) {
    this._selectedSortDirection = value
    this.buildRows()
  }

  private _sourceData: TopXPortsTable | null = null
  public set sourceData(value: TopXPortsTable) {
    this._sourceData = value
    this.buildRows()
  }

  private _rows: TopPortItem[] = []
  public get rows(): TopPortItem[] {
    return this._rows
  }

  private buildRows(): void {
    var model = this
    var rows = model._sourceData ? [...model._sourceData[model._selectedImex]] : []

    var sense = model._selectedSortDirection === 'asc' ? 1 : -1

    rows.sort((a, b) => {
      var aProp: any = a
      var bProp: any = b
      var propertyName = model._selectedSortField as string
      if (propertyName.startsWith('data.')) {
        aProp = a.data
        bProp = b.data
        propertyName = propertyName.substring('data.'.length)
      }

      aProp = aProp[propertyName]
      bProp = bProp[propertyName]
      var comparisonResult = aProp > bProp ? 1 : aProp < bProp ? -1 : 0
      return comparisonResult * sense
    })

    this._rows = rows
  }

  toggleTopPortsImexDirection(): void {
    var model = this
    switch (model._selectedImex) {
      case 'imports':
        model._selectedImex = 'exports'
        break
      case 'exports':
        model._selectedImex = 'imports'
        break
    }
    this.buildRows()
  }

  toggleSort(attribute: TopPortsSort): void {
    var model = this
    var selectedSortFieldDirection = model._selectedSortDirection
    var currentSortField = model._selectedSortField
    if (currentSortField !== attribute) {
      model._selectedSortField = attribute
      selectedSortFieldDirection = 'asc'
    } else {
      selectedSortFieldDirection = selectedSortFieldDirection === 'asc' ? 'desc' : 'asc'
    }

    model._selectedSortDirection = selectedSortFieldDirection
    this.buildRows()
  }
}
