import { Injectable } from '@angular/core'
import { Country, ReleaseDataService } from '@cts/cedar-public-api'
import { asyncScheduler, Observable, scheduled, share, tap } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class ReferenceDataService {
  constructor(private releaseDataService: ReleaseDataService) {}

  private countries: Country[] | null = null
  private pendingCountries$: Observable<Country[]> | null = null

  public get countries$(): Observable<Country[]> {
    if (this.countries != null) {
      //make subsequent accesses call back async like the first one (subscribers to calls to of() call back synchronously,
      // which should make no difference, but consumers technically have to be written to deal with that
      return scheduled([this.countries], asyncScheduler)
    }

    if (this.pendingCountries$ === null) {
      this.pendingCountries$ = this.releaseDataService.getReferenceCountries().pipe(
        tap((countries) => (this.countries = countries)),
        share()
      )
    }
    return this.pendingCountries$
  }
}
